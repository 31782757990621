<template>
    <div id="home-former-products" class="former-products">
        <div class="container">
            <div class="title headline-l">
                {{ $t("homeScreen.formerProducts.title") }}
            </div>
            <div class="text text-l">
                {{ $t("homeScreen.formerProducts.description") }}
            </div>
        </div>
    </div>
</template>
  
<script>  
export default {
    name: "HomeFormerProducts",
    props: {},
    components: {
    },
    data: () => ({}),
    created() {},
    computed: {},
    methods: {
    },
};
</script>
  
<style lang="scss" scoped>
#home-former-products {
    .container{
        max-width: 540px;
        margin: 55px auto 70px auto;
        text-align: center;
        padding: 0 15px;
    }
    @media (max-width: $breakpoint-sm) {
        .container {
            margin: 28px auto 54px auto;
        }
    }
    .title {
      color: $primary-purple;
      margin-bottom: 40px;
    }
    .text {
      color: $text-blue;
    }
}
</style>
  