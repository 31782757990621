<template>
  <div id="home-wallet">
    <div class="light-purple-row">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-12 left-side">
            <div class="logo">
              <img src="/images/logos/blocknote-logo.svg" />
            </div>
            <div class="texts-wrapper">
              <div class="text">
                {{ $t("homeScreen.bno.bnoDescription") }}
              </div>
            </div>
            <div class="button">
              <base-button
                :text="$t('homeScreen.general.prompt')"
                size="large"
                type="primary"
                @clicked="handleButtonClick('bno')"
              />
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="logo">
              <img src="/images/logos/bnox-short-logo.svg" />
            </div>
            <div class="texts-wrapper">
              <div class="text">
                {{ $t("homeScreen.bno.bnoxDescription.firstLine") }}
              </div>
              <br>
              <div class="text">
                {{ $t("homeScreen.bno.bnoxDescription.secondLine") }}
              </div>
            </div>
            <div class="button">
              <base-button
                :text="$t('homeScreen.general.prompt')"
                size="large"
                type="primary"
                @clicked="handleButtonClick('bnox')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeWallet",
  props: {},
  components: {},
  data: () => ({}),
  created() {},
  computed: {},
  methods: {
    handleButtonClick(product) {
      this.$router.push(`/products/${product}`);
    }
  },
};
</script>

<style lang="scss" scoped>
#home-wallet {
  .light-purple-row {
    padding-top: $margin-l;
    padding-bottom: $margin-l;
    .left-side {
      display: flex;
      flex-direction: column;
      .button {
        margin-top: auto;
        margin-bottom: 0;
      }
    }
    .logo {
      margin-bottom: $margin-m;
    }
    .texts-wrapper {
      margin-bottom: $margin-m;
    }
  }
}
@media (max-width: $breakpoint-md) {
  .left-side {
    .button {
      margin-bottom: $margin-m !important;
    }
  }
}
</style>
