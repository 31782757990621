<template>
  <div id="home-wallet">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 col-12 d-flex d-md-flex justify-content-center">
          <img
            class="wallet-image d-none d-md-block"
            src="/images/elements/home/web/wallet_phone.png"
          />
        </div>
        <div class="col-md-6 col-12">
          <div class="title headline-l ">
            {{ $t("homeScreen.wallet.title") }}
          </div>
          <img
            class="main-image d-block d-md-none"
            src="/images/elements/home/mobile/wallet_phone.png"
          />
          <div class="text text-l">
            {{ $t("homeScreen.wallet.description") }}
          </div>
          <base-button
            class="more-button"
            :text="$t('homeScreen.general.prompt')"
            size="small"
            type="primary"
            @clicked="handleButtonClick()"
          />
          <div class="text open-wallet text-l">
            {{ $t("homeScreen.general.openWalletPrompt") }}
          </div>
          <div class="stores d-flex justify-content-center justify-content-md-start">
            <store-button :version="'apple'" />
            <store-button :version="'google'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeWallet",
  props: {},
  components: {},
  data: () => ({}),
  created() {},
  computed: {},
  methods: {
    handleButtonClick() {
      this.$router.push("/wallet");
    }
  },
};
</script>

<style lang="scss" scoped>
#home-wallet {
  min-height: 780px;
  display: flex;
  .container {
    margin-top: auto;
    margin-bottom: auto;
    .title {
      color: $primary-purple;
      margin-bottom: $margin-m;
    }
    .main-image {
      margin-bottom: $margin-s;
    }
    .more-button {
      margin-bottom: $margin-m;
    }
    .text {
      color: $text-blue;
      margin-bottom: 15px;
    }
    .open-wallet {
      font-family: "HKNova-SemiBold", sans-serif;
    }
    .stores {
      display: flex;
      align-items: center;

      .store-button {
        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }
  .wallet-image {
    max-height: 650px;
  }
}
@media (max-width: $breakpoint-sm) {
  #home-wallet {
    min-height: unset !important;
    padding-top: $margin-m !important;
    padding-bottom: $margin-m !important;
    .title {
      margin-bottom: $margin-s !important;
    }
    .open-wallet {
      text-align: center;
    }
  }
}
</style>
