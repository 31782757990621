<template>
  <div id="home-screen">
    <div class="header-background">
      <img
        class="d-none d-md-block header-web-background"
        src="/images/elements/home/web/header_backround_dt.svg"
        alt="header-background"
      />
      <img
        class="d-block d-md-none header-mobile-background"
        src="/images/elements/home/mobile/header_backround_sp.svg"
        alt="header-mobile-background"
      />
    </div>
    <div class="bb-logo-container col-12 col-md-6">
      <img src="/images/logos/bb-logo-full.svg" class="d-none d-md-block bb-logo" />
    </div>
    <jumbotron
      :title="$t('homeScreen.blockChain.title')"
      :description="$t('homeScreen.blockChain.description')"
      :storesLabel="$t('homeScreen.general.openWalletPrompt')"
      :background="'rgba(0,0,0,0)'"
      :textColor="'#46668F'"
      :imgSrc="'/images/elements/home/web/home_header_phone.png'"
      :mobileHeight="660"
      :socials="true"
    >
      <template v-slot:top-image>
        <div class="jumbotron-top-image">
          <img
            class="d-block d-md-none"
            src="/images/elements/home/web/home_header_phone.png"
          />
        </div>
      </template>
    </jumbotron>
    <home-block-joinplatform />
    <home-block-stock />
    <home-natrix />
    <home-blocknote />
    <home-wallet />
    <blog style="background-color: rgba(240, 237, 255, 0.6)" />
    <home-former-products />
  </div>
</template>

<script>
import HomeBlockChain from "@/components/modules/home/HomeBlockChain";
import HomeBlockStock from "@/components/modules/home/HomeBlockStock";
import HomeBlockJoinplatform from "@/components/modules/home/HomeBlockJoinplatform";
import HomeNatrix from "@/components/modules/home/HomeNatrix";
import HomeBlocknote from "@/components/modules/home/HomeBlocknote";
import HomeWallet from "@/components/modules/home/HomeWallet";
import HomeFormerProducts from "@/components/modules/home/HomeFormerProducts";
import Blog from "@/components/modules/blog/Blog";

export default {
  name: "HomeScreen",
  props: {},
  components: {
    HomeBlockChain,
    HomeBlockStock,
    HomeNatrix,
    HomeFormerProducts,
    HomeBlocknote,
    HomeWallet,
    Blog,
    HomeBlockJoinplatform,
  },
  data: () => ({}),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_fonts.scss";
@import "@/assets/scss/_colors.scss";

#home-screen {
  position: relative;
  &::v-deep {
    .header-background {
      position: absolute;
      top: 0;
      right: 0;
      .header-web-background {
        height: 635px;
      }
      .header-mobile-background {
        width: 100vw;
      }
    }
    .jumbotron {
      position: relative;
      .col-md-6 {
        position: unset;
      }
      .illustration {
        position: absolute;
        width: 540px;
        height: 540px;
        right: 150px;

        @media (max-width: 1200px) {
          right: 70px !important;
        }

        @media (max-width: $breakpoint-md) {
          bottom: -50px;
          width: 415px !important;
          right: 30px !important;
        }
      }
      .jumbotron-top-image img {
        margin-top: 60px;
        max-height: 370px;

        @media (max-width: $breakpoint-md) {
          margin-top: 0;
        }
      }

      .top-section {
        @media (max-width: $breakpoint-sm) {
          padding: 60px 45px 0 0 !important;
        }
      }
    }
  
    .left-container {
      max-width: 540px;
    }
    .text {
      font-size: $text-l;
      color: $text-blue;
      line-height: 30px;
    }
  
    .light-purple-row {
      background-color: $lighter-purple;
    }
  
    .ebso-gold-backing-row {
      background-color: #F6F4FF;
    }
  }
}
.bb-logo-container{
  max-width: 1126px;  
  position: absolute;
  top: 1.6%;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: 1200px) {
    max-width: 960px;
    top: 1.2%;
  }
  @media (max-width: 992px) {
    max-width: 720px;
    top: 1.2%;
  }
}
</style>
