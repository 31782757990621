<template>
  <div id="home-block-stock-joinplatform">
    <div class="content-container">
      <div class="content-inner">
        <div class="content-section">
          <div class="title"> {{ this.$t('homeScreen.joinPlatform.title') }} </div>
          <div class="subscription"> {{ this.$t('homeScreen.joinPlatform.subscription') }} </div>
          <base-button
            class=""
            :text="$t('homeScreen.joinPlatform.button')"
            size="large"
            type="blockstock-yellow"
            @clicked="goToJoin()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeBlockStockDiagram from "./HomeBlockStockDiagram";

export default {
  name: "HomeBlockStock",
  props: {},
  components: {
    HomeBlockStockDiagram,
  },
  data: () => ({}),
  created() {},
  computed: {},
  methods: {
    goToJoin() {
      this.$router.push("/joinplatform");
    }
  },
};
</script>

<style lang="scss" scoped>
#home-block-stock-joinplatform {
  margin-top: 100px;
  height: 669px;
  width: 100%;
  background: url('/images/screens/joinplatform/joinplatform_box_bg.png') no-repeat center center;
  background-size: cover;
  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 176px;
    height: 100%;
    background: linear-gradient(to left, transparent, #050533);
    .content-inner {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .content-section {
        @media (min-width: 1200px) {
          width: 1140px;
        }
        .title {
          max-width: 655px;
          margin-bottom: 24px;
          font-size: 40px;
          font-weight: 700;
          line-height: 50px;
          letter-spacing: 0em;
          text-align: left;
          color: white;
        }
        .subscription {
          max-width: 655px;
          margin-bottom: 40px;
          font-size: 16px;
          font-weight: 400;
          line-height: 30px;
          letter-spacing: 0em;
          text-align: left;
          color: white;
        }
      }
    }
  }
}

@media(max-width: 880px) {
  #home-block-stock-joinplatform {
    .content-container {
      padding: 0 120px;
    }
  }
}

@media(max-width: 600px) {
  #home-block-stock-joinplatform {
    .content-container {
      padding: 0 14px;
    }
  }
}
</style>
