<template>
  <div id="home-natrix">
    <div class="container">
      <div class="row flex-sm-row-reverse align-items-center">
        <div
          class="col-md-6 col-12 position-static d-flex justify-content-center natrix-illustration"
        >
          <img
            class="d-none d-md-block natrix-web-illustration"
            src="/images/elements/home/web/natrix_image.png"
            alt="natrix-illustration"
          />
          <img
            class="d-block d-md-none natrix-mobile-illustration"
            src="/images/elements/home/mobile/natrix_image.jpg"
            alt="natrix-illustration"
          />
        </div>
        <div class="col-md-6 col-12">
          <img class="natrix-logo" src="/images/logos/natrix-logo.svg" />
          <div class="text">
            {{ $t("homeScreen.natrix.description") }}
          </div>
          <base-button
            class="button"
            :text="$t('homeScreen.general.prompt')"
            size="large"
            type="primary"
            @clicked="handleButtonClick()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeNatrix",
  props: {},
  components: {},
  data: () => ({}),
  created() {},
  computed: {},
  methods: {
    handleButtonClick() {
      window.open("https://natrix.io/", "_blank");
    }
  },
};
</script>

<style lang="scss" scoped>
#home-natrix {
  position: relative;
  padding-top: $margin-l;
  padding-bottom: $margin-l;
  .natrix-illustration {
    .natrix-web-illustration {
      max-width: 670px;
      height: 470px;
      position: absolute;
      top: 0px;
    }
    .natrix-mobile-illustration {
      max-width: 100vw;
      width: 100vw;
    }
  }
  .natrix-logo {
    margin-bottom: $margin-m;
  }
  .text {
    margin-bottom: $margin-m;
  }
}
@media (max-width: $breakpoint-md) {
  .natrix-web-illustration {
    height: 100% !important;
    width: 100% !important;
    position: static !important;
  }
}
@media (max-width: $breakpoint-sm) {
  #home-natrix {
    padding-top: 0 !important;
    padding-bottom: $margin-m !important;
    .natrix-logo {
      margin-top: $margin-m;
    }
  }
}
</style>
