<template>
  <div id="home-blockchain">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
          <div class="left-container">
            <div class="title">
              {{ $t("homeScreen.blockChain.title") }}
            </div>
            <div class="description text">
              {{ $t("homeScreen.blockChain.description") }}
            </div>
            <div class="open-wallet-prompt text">
              {{ $t("homeScreen.general.openWalletPrompt") }}
            </div>
            <div class="d-flex store-images">
              <img
                class="store-image"
                src="https://via.placeholder.com/150x45"
              />
              <img
                class="store-image"
                src="https://via.placeholder.com/150x45"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 d-none d-lg-block">
          <img class="m-auto" src="https://via.placeholder.com/350x275" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeBlockChain",
  props: {},
  components: {},
  data: () => ({}),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
#home-blockchain {
  padding-top: $margin-l;
  padding-bottom: $margin-l;
  .left-container {
    max-width: 540px;
    .title {
      font-family: "HKNova-Bold";
      font-size: $headline-l;
      color: $primary-purple;
      margin-bottom: $margin-s;
    }
    .description {
      margin-bottom: $margin-s;
      font-weight: 400;
    }
    .open-wallet-prompt {
      margin-bottom: 20px;
      font-weight: 600;
      font-size: 18px;
    }
    .store-images {
      .store-image:first-of-type {
        //   padding-right: $margin-s;
        padding-right: 20px;
      }
    }
  }
}
@media (max-width: $breaking-point-to-md) {
  #home-blockchain {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
}
</style>
