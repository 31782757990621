<template>
  <div v-if="response" id="block-stock-diagram">
    <div class="d-block d-md-none gold-backing-title headline-l">
      {{ $t("homeScreen.eBsoGoldBacking.title") }}
    </div>
    <div class="title-row">
      <div class="title">
        {{
          $t("homeScreen.bso.eBSODiagram.title", { sum: formatTotalPoolBalance(response.totalPoolBSOBalance) })
        }}
      </div>
    </div>
    <div class="content-container">
      <div class="content">
        <div class="row">
          <div class="col-6">
            <div class="content-title" :class="isSubPage ? 'sub' : ''">
              {{ $t("homeScreen.bso.eBSODiagram.1BSO.title") }}
            </div>
          </div>
          <div class="col-6">
            <div class="content-title text-right" :class="isSubPage ? 'sub' : ''">
              {{ $t("homeScreen.bso.eBSODiagram.total.title") }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="content-gold content-text" :class="isSubPage ? 'sub' : ''">
              {{
                $t("homeScreen.bso.eBSODiagram.1BSO.gold", {
                  gold: response.AUValuePerBSO.toFixed(3),
                })
              }}
            </div>
          </div>
          <div class="col-6">
            <div class="content-gold content-text text-right" :class="isSubPage ? 'sub' : ''">
              {{
                $t("homeScreen.bso.eBSODiagram.total.gold", {
                  gold: convertGrToKg(response.totalPoolAUBalance),
                })
              }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="content-sums content-text" :class="isSubPage ? 'sub' : ''">
              {{
                $t("homeScreen.bso.eBSODiagram.1BSO.sum", {
                  sum: response.EURValuePerBSO.toFixed(2),
                })
              }}
            </div>
          </div>
          <div class="col-6 pl-0">
            <div class="content-sums content-text text-right" :class="isSubPage ? 'sub' : ''">
              {{
                $t("homeScreen.bso.eBSODiagram.total.sum", {
                  sum: convertToMillion(response.totalPoolEURValue),
                })
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "@/api";

export default {
  name: "HomeBlockStockDiagram",
  props: {},
  components: {},
  data: () => ({
    response: undefined
  }),
  async created() {
    let apiResp = await Api.get("BSOgoldPool");
    this.response = await apiResp.json();
  },
  computed: {
    isSubPage() {
      return location.href.includes('products/ebso');
    }
  },
  methods: {
    convertGrToKg(number) {
      return (number/1000).toFixed(2);
    },
    convertToMillion(number) {
      return (number/1000000).toFixed(2);
    },
    formatTotalPoolBalance(number) {
      let numberFormat = new Intl.NumberFormat('de-DE')
      return numberFormat.format(number)
    }
  },
};
</script>

<style lang="scss" scoped>
#block-stock-diagram {
  width: 430px;
  min-height: 250px;
  padding: 0 0 25px;
  border: 1px solid #fcbc0d;
  border-radius: 12px;
  .gold-backing-title {
    color: #fcbb11;
    margin-top: $margin-m;
    margin-bottom: $margin-m;
    margin-right: 15px;
    margin-left: 15px;
  }
  .title-row {
    border-radius: 10px 10px 0 0;
    height: 75px;
    background: linear-gradient(to right, #fcbc0d, #f39665);
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      color: #1b1b1b;
      font-family: "HKNova-Bold", sans-serif;
      font-size: 32px;
      line-height: 50px;
    }
  }
  .content-container {
    margin-top: $margin-s;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      width: 100%;
      margin-right: 20px;
      margin-left: 20px;
      .content-title {
        font-weight: bold;
        font-size: $headline;
        line-height: 35px;
        color: $text-blue;
        margin-bottom: 20px;
        &.sub {
          color: #fefefe;
        }
      }
      .content-text {
        font-size: $text-l;
        line-height: 30px;
        color: $text-blue;
        &.sub {
          color: #fefefe;
        }
      }
    }
  }
}
@media (max-width: $breakpoint-sm) {
  #block-stock-diagram {
    width: 100vw !important;
    border-radius: 0 !important;
    border-left: none !important;
    border-right: none !important;
    .title-row {
      width: 100vw !important;
      border-radius: 0 !important;
    }
  }
}
</style>
