<template>
  <div id="home-block-stock" class="ebso-gold-backing-row">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-12">
          <div class="left-container">
            <img class="logo" src="/images/logos/e-blockstock-logo.svg" />
            <div class="white-text first-text">
              {{ $t("homeScreen.bso.eBsoDescription") }}
            </div>
            <div class="col-md-6 col-12 diagram d-flex d-md-none justify-content-center">
              <home-block-stock-diagram />
            </div>
            <div class="white-sub-title">
              {{ $t("homeScreen.eBsoGoldBacking.title") }}
            </div>
            <div class="white-text second-text">
              {{ $t("homeScreen.eBsoGoldBacking.description") }}
            </div>
            <base-button
              class=""
              :text="$t('homeScreen.general.prompt')"
              size="large"
              type="blockstock-yellow"
              @clicked="handleButtonClick()"
            />
          </div>
        </div>
        <div class="col-lg-6 col-12 d-none d-md-block pt">
          <home-block-stock-diagram class="m-auto" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeBlockStockDiagram from "./HomeBlockStockDiagram";

export default {
  name: "HomeBlockStock",
  props: {},
  components: {
    HomeBlockStockDiagram,
  },
  data: () => ({}),
  created() {},
  computed: {},
  methods: {
    handleButtonClick() {
      this.$router.push("/products/ebso");
    }
  },
};
</script>

<style lang="scss" scoped>
#home-block-stock {
  padding-top: $margin-l;
  padding-bottom: $margin-l;

  @media screen and (min-width: 768px) and (max-width: $breakpoint-md) {
    .pt {
      padding-top: 35px;
    }
  }

  @media (max-width: $breaking-point-to-sm) {
    margin-top: 0;
  }

  .logo {
    margin-bottom: $margin-m;
    max-height: 75px;
  }

  .first-text {
    margin-bottom: $margin-m;
  }

  .diagram {
    margin-bottom: $margin-m;
  }

  .white-sub-title {
    font-family: "HKNova-Bold";
    font-size: $headline;
    color: rgba(252, 187, 17, 1);
    margin-bottom: 10px;
  }

  .second-text {
    margin-bottom: $margin-m;
  }

  .white-text {
    font-size: $text-l;
    color: $text-blue;
  }
}
</style>
